import React from "react"
import BackgroundSlider from "gatsby-image-background-slider"
import { useStaticQuery, graphql } from "gatsby"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const buttonStyle = {
  backgroundColor: "#EDC02B",
  borderColor: "#EDC02B",
  borderRadius: "50px",
  fontFamily: "allotrope, sans-serif",
  fontWeight: 700,
  fontSize: "1rem",
  paddingTop: ".5rem",
  paddingLeft: "2rem",
  paddingRight: "2rem",
}

const BackgroundSliderComponent = () => {
  return (
    <div style={{ width: "100%", 
    // position: "relative", height: "100%"
    }}>
      <div
        style={{
        //   position: "absolute",
        //   top: "0%",
        //   left: "0%",
          color: "white",
          textAlign: "center",
          backgroundColor: "#3E597F",
          width: "100%",
          padding: "2%",
        }}
      >
        <div style={{}}>
          <h1 style={{ color: "white" }}>
            USS IDAHO Christening Date in Groton, Connecticut has been set for
            Saturday, March 16 2024
          </h1>

          <Link
            style={buttonStyle}
            to="/upcoming-events"
            className="btn btn-primary btn-sm"
          >
            Click for more Christening Information
          </Link>
        </div>
      </div>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "images" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 4000, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
        initDelay={12}
        transition={4}
        duration={10}
        images={["sub-1.jpg", "sub-2.jpg", "sub-3.jpg"]}
        style={{
          overflow: "hidden",
        }}
      />
    </div>
  )
}

export default BackgroundSliderComponent
